define('ember-notify/index', ['exports', 'ember-notify/message'], function (exports, _message) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function aliasToShow(type) {
    return function (message, options) {
      return this.show(type, message, options);
    };
  }

  var Notify = Ember.Service.extend({

    info: aliasToShow('info'),
    success: aliasToShow('success'),
    warning: aliasToShow('warning'),
    alert: aliasToShow('alert'),
    error: aliasToShow('error'),

    init: function init() {
      this._super.apply(this, arguments);
      this.pending = [];
    },
    show: function show(type, text, options) {
      var assign = assign || Ember.merge;

      // If the text passed is `SafeString`, convert it
      if (Ember.String.isHTMLSafe(text)) {
        text = text.toString();
      }
      if ((typeof text === 'undefined' ? 'undefined' : _typeof(text)) === 'object') {
        options = text;
        text = null;
      }
      var message = _message.default.create(assign({
        text: text,
        type: type
      }, options));
      var target = this.get('target');
      if (target) {
        target.show(message);
      } else {
        this.pending.push(message);
      }
      return message;
    },
    setTarget: function setTarget(target) {
      this.set('target', target);
      if (target) {
        this.pending.map(function (message) {
          return target.show(message);
        });
        this.pending = [];
      }
    }
  }).reopenClass({
    // set to true to disable testing optimizations that are enabled when Ember.testing is true
    testing: false
  });

  exports.default = Notify.reopenClass({
    property: function property() {
      return Ember.computed(function () {
        return Notify.create();
      });
    }
  });
});