define("ember-power-select/components/power-select/before-options", ["exports", "ember-power-select/templates/components/power-select/before-options"], function (_exports, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    layout: _beforeOptions.default,
    autofocus: true,
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('autofocus')) {
        this.focusInput();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('searchEnabled')) {
        Ember.run.scheduleOnce('actions', this, this.get('select').actions.search, '');
      }
    },
    // Actions
    actions: {
      onKeydown: function onKeydown(e) {
        var onKeydown = this.get('onKeydown');
        if (onKeydown(e) === false) {
          return false;
        }
        if (e.keyCode === 13) {
          var select = this.get('select');
          select.actions.close(e);
        }
      }
    },
    // Methods
    focusInput: function focusInput() {
      this.input = document.querySelector(".ember-power-select-search-input[aria-controls=\"".concat(this.get('listboxId'), "\"]"));
      if (this.input) {
        Ember.run.later(this.input, 'focus', 0);
      }
    }
  });
});