define("ember-intl/-private/utils/flatten", ["exports", "ember-intl/-private/utils/empty-object"], function (_exports, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flatten;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  /**
   * @private
   * @hide
   */
  function flatten(src) {
    var result = new _emptyObject.default();
    for (var key in src) {
      if (!hasOwnProperty.call(src, key)) {
        continue;
      }
      var value = src[key];
      if (_typeof(value) === 'object' && value) {
        var hash = flatten(value);
        for (var suffix in hash) {
          result["".concat(key, ".").concat(suffix)] = hash[suffix];
        }
      } else {
        result[key] = value;
      }
    }
    return result;
  }
});