define("ember-diff-attrs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function isEqual(key, a, b) {
    return a === b;
  }
  function _default(keys, hook) {
    var oldValuesMap = new WeakMap();
    var isEqualFunc = isEqual;
    if (_typeof(keys) === 'object') {
      var options = keys;
      keys = options.keys;
      if (options.isEqual) {
        isEqualFunc = options.isEqual;
      }
      if (options.hook) {
        hook = options.hook;
      }
    } else if (arguments.length > 1) {
      keys = [].slice.call(arguments);
      hook = keys.pop();
    } else {
      throw new Error('Invalid `diffAttrs` argument. Expected either one or more strings and a function, or an options hash.');
    }
    return function () {
      var _this = this;
      var changedAttrs = {};
      var oldValues;
      var isFirstCall = false;
      if (!oldValuesMap.has(this)) {
        isFirstCall = true;
        oldValuesMap.set(this, {});
      }
      oldValues = oldValuesMap.get(this);
      keys.forEach(function (key) {
        var value = _this.get(key);
        if (!isEqualFunc(key, oldValues[key], value)) {
          changedAttrs[key] = [oldValues[key], value];
          oldValues[key] = value;
        }
      });
      hook.apply(this, [isFirstCall ? null : changedAttrs].concat(Array.prototype.slice.call(arguments)));
    };
  }
});