define('ember-notify/message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EMPTY_ARRAY = [];

  exports.default = Ember.Object.extend({
    text: null,
    html: '',
    type: 'info',
    closeAfter: undefined,
    visible: undefined,
    classNames: EMPTY_ARRAY
  });
});