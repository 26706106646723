define("ember-power-select/components/power-select/trigger", ["exports", "ember-power-select/templates/components/power-select/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _trigger.default,
    tagName: '',
    // Actions
    actions: {
      clear: function clear(e) {
        e.stopPropagation();
        this.get('select').actions.select(null);
        if (e.type === 'touchstart') {
          return false;
        }
      }
    }
  });
});