define("lodash/_Symbol", ["exports", "lodash/_root"], function (_exports, _root) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Built-in value references. */
  var _Symbol = _root.default.Symbol;
  var _default = _exports.default = _Symbol;
});