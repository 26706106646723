define("ember-cached-decorator-polyfill/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function cached() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var target = args[0],
      key = args[1],
      descriptor = args[2];

    // Error on `@cached()`, `@cached(...args)`, and `@cached propName = value;`
    (false && !(target !== undefined) && Ember.assert('You attempted to use @cached(), which is not necessary nor supported. Remove the parentheses and you will be good to go!', target !== undefined));
    (false && !(_typeof(target) === 'object' && typeof key === 'string' && _typeof(descriptor) === 'object' && args.length === 3) && Ember.assert("You attempted to use @cached on with ".concat(args.length > 1 ? 'arguments' : 'an argument', " ( @cached(").concat(args.map(function (d) {
      return "'".concat(d, "'");
    }).join(', '), "), which is not supported. Dependencies are automatically tracked, so you can just use ", '`@cached`'), _typeof(target) === 'object' && typeof key === 'string' && _typeof(descriptor) === 'object' && args.length === 3));
    (false && !(typeof descriptor.get == 'function') && Ember.assert("The @cached decorator must be applied to getters. '".concat(key, "' is not a getter."), typeof descriptor.get == 'function'));
    var caches = new WeakMap();
    var getter = descriptor.get;
    descriptor.get = function () {
      if (!caches.has(this)) caches.set(this, Ember._createCache(getter.bind(this)));
      return Ember._cacheGetValue(caches.get(this));
    };
  }
});