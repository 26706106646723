define("ember-intl/helpers/-format-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   * @private
   * @hide
   */
  var AbstractHelper = Ember.Helper.extend({
    intl: null,
    init: function init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }
      this._super();
      this.intl = Ember.getOwner(this).lookup('service:intl');
      this.intl.on('localeChanged', this, 'recompute');
    },
    format: function format() {
      throw new Error('not implemented');
    },
    compute: function compute(_ref, options) {
      var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];
      if (Ember.isEmpty(value)) {
        var _options$allowEmpty;
        if ((_options$allowEmpty = options.allowEmpty) !== null && _options$allowEmpty !== void 0 ? _options$allowEmpty : this.allowEmpty) {
          return;
        }
        if (typeof value === 'undefined') {
          throw new Error("".concat(this, " helper requires value attribute."));
        }
      }
      return this.format(value, options);
    },
    willDestroy: function willDestroy() {
      this._super();
      this.intl.off('localeChanged', this, 'recompute');
    }
  });
  var _default = _exports.default = AbstractHelper;
});