define("ember-sortable/helpers/waiters", [], function () {
  "use strict";

  var dropStarts = 0;
  /**
   * Watch for transitions to start and end before allowing ember to
   * continue the test suite. Since we can't use transitionstart reliably in
   * all browsers, but we can use transitionend, we emit our own custom
   * event that is only used in tests.
   */
  Ember.Test.registerWaiter(function () {
    return dropStarts === 0;
  });
  document.addEventListener('ember-sortable-drop-start', function () {
    dropStarts++;
  });
  document.addEventListener('ember-sortable-drop-stop', function () {
    dropStarts--;
  });
});